<template>
  <div class="row" id="body-login">
    <div class="col-12">
      <div id="login">
        <div id="form-login">
          <form method="post">
            <div id="titulo">
              <!-- <img src="<?=IMG?>logo-tim-xs.png" alt="Tim"> -->
              <p>Iniciar sessão</p>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <input
                class="mdl-textfield__input"
                type="email"
                id="email"
                name="email"
                required
              />
              <label class="mdl-textfield__label" for="email">E-mail</label>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <input
                class="mdl-textfield__input"
                type="password"
                id="senha"
                name="senha"
                required
              />
              <label class="mdl-textfield__label" for="senha">Senha</label>
            </div>
            <div class="clearfix"></div>
            <div class="row acoes">
              <div class="col-sm-6 col-xs-12">
                <a
                  class="esqueceu-senha"
                  data-toggle="modal"
                  data-target="#modalEsqueciSenha"
                  >Esqueci minha senha</a
                >
              </div>
              <div class="col-sm-6 col-xs-12">
                <input type="submit" class="btn" value="Seguinte" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
module.exports = {
  data: () => ({
    msg: "login",
  }),
  mounted() {
    console.log(this.$route);
  },
};
</script>


<style lang="scss">
@import "../theme/theme.scss";
</style>