<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>Blog {{ article.id ? "#" + article.id : "" }}</h1>
        <p>{{ article.id ? `Editando o artigo: ${article.pt.title}` : "Novo artigo" }}</p>
        <p>{{ article.updated_at }}</p>
        <div class="form-check form-switch" v-if="article" role="button">
          <input
            class="form-check-input mb-3"
            type="checkbox"
            id="flexSwitchCheckChecked"
            v-model="article.is_published"
          />
          <label class="form-check-label" for="flexSwitchCheckChecked">{{
            article.is_published ? "Publicado" : "Rascunho"
          }}</label>
        </div>
      </div>
    </div>
    <form ref="form_aticle" v-on:submit="submit($event)" v-if="article">
      <Nav />
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-pt"
          role="tabpanel"
          aria-labelledby="nav-pt-tab"
        >
          <Form lang="pt" title="Português" v-bind:article="article.pt" />
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <Form lang="eu" title="Inglês" v-bind:article="article.eu" />
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <Form lang="es" title="Espanhol" v-bind:article="article.es" />
        </div>
        <button v-bind:disabled="is_saving" v-bind:class="{ 'btn-primary': !success, 'btn-success': success}" class="btn" type="submit">
          <span
            v-if="is_saving"
            
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ btn_text }}
        </button>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
const Form = require("./components/article/Form.vue");
const Nav = require("./components/article/Nav.vue");
module.exports = {
  data: () => ({
    success: false,
    is_saving: false,
    btn_text: "Salvar",
    article: {
      id: false,
      is_published: false,
      thumbnail: "https://via.placeholder.com/300",
      main_image: "https://via.placeholder.com/300",
      pt: {
        title: null,
        content: null,
      },
      eu: {
        title: null,
        content: null,
      },
      es: {
        title: null,
        content: null,
      },
    },
  }),
  methods: {
    submit(event) {
      event.preventDefault();
      let env = this;
      this.is_saving = true;
      this.btn_text = "Salvando...";
      this.$http.post(`${this.$url}admin/saveArticle`, this.article)
      .then((response) => {
        if(response.data.result){
          this.is_saving = false;
          env.btn_text = "Salvo com sucesso!";
          env.success = true;
          setTimeout(() => {
            env.btn_text = "Salvar";
            env.success = false;

            env.$router.push('/article/' + response.data.result);
          }, 2000);
        } else{
          this.is_saving = false;
          alert('Erro ao salvar artigo');
        }
      })
    },
    getArticle() {
      let env = this;
      let id = this.$route.params.id ? this.$route.params.id : false;
      if (id) {
        this.$http.get(`admin/getArticle/${id}`).then((response) => {
          if (response.data.result) {
            this.article = response.data.result;
            this.article.id = id;
          } else{
            this.article = this.defaultArticle;
          }
        });
      } else{
            this.article = this.defaultArticle;
      }
    },
  },
  components: {
    Form,
    Nav,
  },
  mounted() {
    this.defaultArticle = this.article;
    this.getArticle();
  },
  beforeMount() {},
  setup(props) {},
  watch: {
    "$route.params.id": {
      handler: function (from, to) {
        this.getArticle();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
