
<template>
 <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-pt-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-pt"
            type="button"
            role="tab"
            aria-controls="nav-pt"
            aria-selected="true"
          >
            PT
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            EN
          </button>
          <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            ES
          </button>
        </div>
      </nav>
</template>

<script type="text/javascript">
module.exports = {
  data: () => ({
  }),
  components: {},
};
</script>
