const Index = require('./pages/index.vue');
const Login = require('./pages/Login.vue');
const Article = require('./pages/Article.vue');

module.exports = [
    { path: '/', component: Index },
    { path: '/login', component: Login },
    { path: '/article', component: Article },
    { path: '//:id', component: Article }
  ]
