<template>
  <!-- <MainLayout> -->
    <div class="row">
      <div class="col-xs-12">
        <h1>Bem vindo(a)!</h1>
        <h3 class="loja-nome">
          Você está logado na administração da <span>{nome}</span>
        </h3>
      </div>
    </div>
  <!-- </MainLayout> -->
</template>

<script type="text/javascript">

const MainLayout = require("../layouts/Main.vue");

module.exports = {
  data: () => ({}),
  components: {
    MainLayout,
  },
};
</script>
