<template>
<MainLayout>
    <transition name="fade" mode="out-in">
        <router-view/>
    </transition>
</MainLayout>
</template>

<script type="text/javascript">

const MainLayout = require("../layouts/Main.vue");

module.exports = {
  data: () => ({}),
  components: {
    MainLayout,
  },
};
</script>


<style lang="css">

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}


</style>