<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div class="mb-3">
        <label for="title" class="form-label">Título</label>
        <input name="title" v-model="article.title" type="text" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="content" class="form-label"> Conteúdo </label>
        <VueEditor name="content" v-model="article.content" />
      </div>
      <!-- <button type="submit">axios</button> -->
    </div>
  </div>
</template>

<script type="text/javascript">
module.exports = {
  data: () => ({}),
  props: ["language", "title", "article"],
  components: {},
  mounted() {},
  methods: {
    axios: function () {
      this.$http
        .get("https://api.coindesk.com/v1/bpi/currentprice.json")
        .then((response) => (this.info = response));
    },
  },
};
</script>
