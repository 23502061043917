<template>
  <div class="mdl-layout mdl-js-layout">
    <Header></Header>
    <MenuLateral></MenuLateral>
    <main class="mdl-layout__content">
      <div class="page-content">
        <div class="container">
          <slot></slot>
          <div class="row">
            <div class="col-xs-12"><hr class="bottom-form" /></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script type="text/javascript">
const Header = require("../components/Header.vue");
const MenuLateral = require("../components/MenuLateral.vue");

module.exports = {
  data: () => ({}),
  components: {
    Header,
    MenuLateral,
  },
};
</script>
