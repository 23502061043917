
const Vue = require('vue');

const VueRouter = require('vue-router');
Vue.use(VueRouter);

const mdl = require('material-design-lite');
const VueEditor = require('vue2-editor');
const Axios = require('axios');

Vue.prototype.$http = Axios;


Vue.use(mdl);
Vue.use(VueEditor);

const App = require('./pages/App.vue');
const Login = require('./pages/Login.vue');

const routes = require('./routes');

const router = new VueRouter({
  routes
})

let adminElement = document.getElementById('admin');
if(adminElement){
  let AdminApp = new Vue({
    router,
    el: '#admin',
    data: {
    },
    computed: {
    },
    render(h) {
      return h(App)
    },
    mounted() {
      console.log("%c 🚀 MaxiAdmin.Vue is running!!! => src/assets/vue/README.md 🚀", "color: #fff; background: #42b883; padding: 10px; text-align: center;width: 100%; font-size: 15px; font-weight: bold;font-family: 'Roboto', sans-serif;");
      let url = document.getElementsByTagName("body")[0].getAttribute("data-url");
      Vue.prototype.$url = url;
    },
    watch: {
      $route(to, from) {
        this.$nextTick(() => {
          window.componentHandler.upgradeDom();
          window.componentHandler.upgradeAllRegistered();
        });
      }
    }
  });
}


let loginElement = document.getElementById('login');
if (loginElement) {
  let LoginApp = new Vue({
    // router,
    el: '#login',
    data: {
    },
    computed: {
    },
    render(h) {
      return h(Login)
    },
    mounted() {
      console.log("%c 🚀 MaxiAdmin.Vue is running!!! => src/assets/vue/README.md 🚀", "color: #fff; background: #42b883; padding: 10px; text-align: center;width: 100%; font-size: 15px; font-weight: bold;font-family: 'Roboto', sans-serif;");
    },
    watch: {
      $route(to, from) {
        this.$nextTick(() => {
          window.componentHandler.upgradeDom();
          window.componentHandler.upgradeAllRegistered();
        });
      }
    }
  });
}