<template>
  <div class="mdl-layout__drawer">
    <span class="">
      <a href="admin">
        <img class="logo logo-black" src="img/logo.png" alt="" />
      </a>
    </span>
    <nav class="mdl-navigation">
      <a class="mdl-navigation__link" href="admin/logout">Sair</a>
    </nav>
  </div>
</template>

<script type="text/javascript">
module.exports = {
  data: () => ({
    msg: "login",
  }),
};
</script>
