<template>
  <div>
    <header class="mdl-layout__header mdl-layout__header--scroll">
      <div class="mdl-layout__header-row">
        <span class="mdl-layout-title">
        <router-link to="/"><img class="logo logo-white" src="img/logo.png" alt="" /></router-link>
        </span>
        <div class="mdl-layout-spacer"></div>
        <nav class="mdl-navigation mdl-layout--large-screen-only">
          <a id="menu-noticias" class="mdl-button mdl-js-button menu-dropdown">
            <b>Blog</b>
            <i class="material-icons">arrow_drop_down</i>
          </a>
          <ul
            id="menu-user-dropdown"
            class="mdl-menu mdl-menu--bottom mdl-js-menu mdl-js-ripple-effect menu-dropdown"
            for="menu-noticias"
          >
              <router-link to="/article/0">
                <li class="mdl-menu__item">
                <!-- <a href="admin/add/article">Adicionar</a> -->
                Adicionar
              </li>
              </router-link>
            <li class="mdl-menu__item">
              <a href="admin/list/article">Listar</a>
            </li>
          </ul>
          <a id="menu-user" class="mdl-button mdl-js-button menu-dropdown">
            <i class="material-icons">account_circle</i>
            <b>Administração</b>
            <i class="material-icons">arrow_drop_down</i>
          </a>
          <ul
            id="menu-user-dropdown"
            class="mdl-menu mdl-menu--bottom mdl-js-menu mdl-js-ripple-effect"
            for="menu-user"
          >
            <li class="mdl-menu__item"><a href="admin/lista/usuarios">Usuários</a></li>
            <li class="mdl-menu__item"><a href="admin/senha">Trocar Minha Senha</a></li>
            <li class="mdl-menu__item"><a href="admin/logout">Sair</a></li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script type="text/javascript">
module.exports = {
  data: () => ({
    msg: "login",
  }),
};
</script>


<style lang="scss" scoped>
  
  .mdl-layout-title{
    img{
      max-height: 4rem
    }
  }
</style>